import React, { useState } from "react"
import { graphql, Link } from "gatsby"

import { PlusSmIcon } from '@heroicons/react/solid'

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import TopicList from "../components/topic/topiclist"
import Categories from "../components/topic/categories"

import Tools from "../components/tools/toolsList"

import { convertToSlug } from "../utils/text";

const ToolCategoryPage = ({data, pageContext}) => {
  const isMobile = (typeof window !== 'undefined') && window.screen.width < 600;

  // assemble the categories from list of all tools
  const tools = pageContext.tools;
  var categories = [];
  tools.filter(t => t.categories).forEach(t => {
    t.categories.split(',').forEach(c => {
      const index = categories.map(c => c.name).indexOf(c);
      if (index === -1){
        categories.push({'slug': convertToSlug(c), 'name': c, 'count': 1})
      } else {
        categories[index].count += 1;
      }
    })
  });
  const allCategories = [
    {'slug': 'all', 'name': 'All', 'count': tools.length},
    ...categories.sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0)),
    // {'slug': 'uncategorized', 'name': 'Uncategorized', 'count': tools.filter(t => !t.category).length},
  ]

  // current category is all (or if passed through context, one of them)
  var currentCategory = allCategories[0];
  if (pageContext && pageContext.slug){
    currentCategory = allCategories.filter(c => c.slug === pageContext.slug)[0]
  }

  // filter the tools by category
  var filteredTools = tools;
  if (currentCategory && currentCategory.slug !== 'all'){
    filteredTools = filteredTools.filter(t => t.categories.includes(currentCategory.name))
  }

  // sort to include promoted ones at the top
  filteredTools = filteredTools.sort((a,b) => (a.promoted && !b.promoted) ? -1 : ((!a.promoted && b.promoted) ? 1 : 0));

  return (
    <Layout
      pageTitle={pageContext.name ? `Community ${pageContext.name} Tools` : 'All Community Tools'}
      enableShare={true}
    >
      <SEO
        title={pageContext.name ? `Community ${pageContext.name} Tools` : 'Online Community Tools'}
        description="Discover tools to help you run your community" 
        generateImage={true}
        path={pageContext.slug ? `/tools/${pageContext.slug}/` : '/tools/'}
      />

      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex items-center">
          <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">
            {pageContext.name ? `Tools for Community ${pageContext.name}` : 'All Tools'}
          </h2>
          <span className="flex-shrink-0 font-semibold text-xs text-gray-500 bg-white px-1 pr-2">
            {filteredTools.length}
          </span>
        </div>
      </div>
      <div className="mt-2 grid grid-cols-3 sm:grid-cols-4 sm:gap-x-6 xl:gap-x-8">
        <div className="hidden sm:block mt-2 pr-6">
          <div className={`space-y-1 -mx-3`}>
            {allCategories.map(c=>(
              <Link
                key={c.slug}
                // onClick={() => setCurrentCategory(c)}
                to={c.slug === 'all' ? '/tools/' : `/tools/${c.slug}/`}
                className={`p-2 px-3 rounded-md flex items-center cursor-pointer ${c.slug === currentCategory.slug ? 'bg-rose-100' : 'hover:bg-rose-50'}`}
              >
                <div className="font-semibold">{c.name}</div>
                <div className="ml-auto font-bold opacity-25 text-sm">{c.count}</div>
              </Link>
            ))}
          </div>
        </div>

        <div className="col-span-3">
          <Tools tools={filteredTools} showRankings={true} />
        </div>
      </div>
    </Layout>
  )
}

// export const query = graphql`
// query {
//   allTools: allGoogleSpreadsheetSourceTools(sort: {fields: order, order: ASC}) {
//     totalCount
//     nodes {
//       order
//       slug
//       name
//       description
//       categories
//       platforms
//       logo
//       link
//     }
//   }
// }
// `

export default ToolCategoryPage
