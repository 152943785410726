import React, { useState } from "react"
import { Link } from "gatsby"

import {
  ChevronRightIcon
} from '@heroicons/react/outline'

import { unslugify, readableNumber } from '../../utils/text'
import { trackEvent } from "../../utils/tracking"

import Image from "../common/image"
// import withLocation from "../common/withlocation"

const ToolsList = ({
  tools, 
  showRankings,
}) => {
  const defaultIncrement = 20;
  const [countToShow, setCountToShow] = useState(defaultIncrement);  // user expands manually
  const toolsToShow = tools.slice(0, countToShow);
  
  return (
    <React.Fragment>
      <ul className="divide-y divide-gray-200 text-left">
        {toolsToShow.map((t, index) => (
          <li key={t.slug}>
            <a
              href={t.link} target="_blank"
              onClick={() => {
                // track event
                trackEvent('ToolRedirect', {
                  'event_label': t.name,
                  'slug': t.slug,
                  'name': t.name,
                  'page': 'ToolsList',
                  'index': index,
                })
              }}
              // to={`/tools/${t.slug}/`}
              className="block hover:bg-gray-50 group"
            >
              <div className="py-4 px-2 sm:px-4 flex items-center sm:px-6">
                <div className="min-w-0 flex-1 sm:flex items-center">
                  
                  <div className="p-4 pl-0 flex-shrink-0 flex-grow-0 relative">
                    {showRankings && index < 10 ? (
                      <div className="hidden sm:block absolute py-1 px-3 top-10 right-24 rounded-full group-hover:bg-gray-50 text-gray-300 text-lg font-bold">#{index + 1}</div>
                    ) : ''}
                    {t.logo ? (
                      <img className="h-20 w-20 object-contain rounded-md object-left sm:object-center" src={t.logo} alt={`${t.name} logo`} width="70" height="42" loading="lazy" />
                    ) : (
                      <Image className="h-20 w-20 object-contain opacity-50 object-left sm:object-center" fileName="icon.png" alt="Community Placeholder" width="70" height="42" loading="lazy"/>   
                    )}
                  </div>
                  <div className="mr-4">
                    <div className="flex text-sm">
                      <h3 className="font-medium text-sm text-rose-500 truncate max-w-sm">{t.name}</h3>
                      {t.promoted ? (
                        <span className="ml-2 text-xs bg-yellow-400 font-semibold text-white rounded-md px-2 py-0.5">Featured</span>
                      ) : ''}
                    </div>
                    {t.categories && t.categories.split(',').length ? (
                      <div className="flex items-center align-start font-normal">
                        <p className="text-xs text-gray-400">
                          {t.categories.split(',').join(', ')} tool
                          {t.platforms ? (
                            <React.Fragment>
                              {` `}for {t.platforms.split(',').map(t => unslugify(t)).join(', ')} communities
                            </React.Fragment>
                          ) : ''}
                        </p>
                      </div>
                    ) : ''}
                    {/*{t.platforms && t.platforms.length ? (
                      <div className="flex items-center align-start font-normal">
                        <p className="text-xs text-gray-400">
                          For {t.platforms.split(',').map(t => unslugify(t)).join(', ')}
                        </p>
                      </div>
                    ) : ''}*/}

                    {t.description ? (
                      <div className="mt-2 text-sm text-gray-600 line-clamp-4">
                        {t.description}
                      </div>
                    ) : ''}
                  </div>
                </div>
                <div className="ml-5 flex-shrink-0">
                  <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
      {toolsToShow.length < tools.length ? (
        <div className="p-4 text-center text-gray-600 font-semibold text-xs opacity-75 hover:opacity-100 cursor-pointer"
          onClick={() => setCountToShow(countToShow + defaultIncrement)}
        >
          {tools.length - toolsToShow.length} more {tools.length - toolsToShow.length === 1 ? 'tool' : 'tools'} →
        </div>
      ) : ''}
      {tools.length === 0 ? (
        <div className="p-8 text-center text-gray-600 font-semibold opacity-75 h-96 cursor-pointer"
          // onClick={() => setCountToShow(countToShow + defaultIncrement)}
        >
          No tools that meet these filters
        </div>
      ) : ''}
    </React.Fragment>
  )

}


export default ToolsList;
